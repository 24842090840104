import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import COLORS from 'ui/constants/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const greenStyle = definePartsStyle({
    // define the part you're going to style
    control: {
        borderColor: COLORS.Line.value,
        _checked: {
            background: COLORS.DigitMint.LightGreen.T500.value,
            borderColor: COLORS.DigitMint.LightGreen.T500.value,
            _hover: {
                bg: COLORS.DigitMint.LightGreen.T500.value,
                borderColor: COLORS.DigitMint.LightGreen.T500.value,
            },
        },
    },
});

export const radioTheme = defineMultiStyleConfig({
    variants: { green: greenStyle },
});
