import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableAnatomy } from '@chakra-ui/anatomy';
import COLORS from 'ui/constants/colors';
import FONTS from 'ui/constants/fonts';
import { inter } from '../../../../../constants/font-family';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

export const borderedTableStyle = definePartsStyle({
    table: {
        border: '1px solid',
        borderColor: COLORS.Line.value,
        borderCollapse: 'separate',
        borderSpacing: 0,
        bgColor: COLORS.White.T500.value,
        borderRadius: '.5rem',
    },
    thead: {
        tr: {
            height: '1.5rem',
            _first: {
                th: {
                    borderTop: 'none',
                },
            },
        },
        th: {
            ...FONTS.T1.T12px.Medium500.value,
            ...inter.style,
            textTransform: 'none',
            color: COLORS.Text.T300.value,
            textAlign: 'center',
            borderTop: '1px solid',
            borderRight: '1px solid',
            borderColor: COLORS.Line.value,
            padding: '.5rem 0',
            _last: {
                borderRight: 'none',
            },
        },
    },
    tbody: {
        tr: {
            height: '2.75rem',
        },
        td: {
            borderRight: '1px solid',
            borderTop: '1px solid',
            borderColor: COLORS.Line.value,
            color: COLORS.Text.T400.value,
            padding: 0,
            fontSize: '12px',
            textAlign: 'center',
            fontWeight: 500,
            _last: {
                borderRight: 'none',
            },
        },
    },
});
