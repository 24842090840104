import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import COLORS from 'ui/constants/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const greenStyle = definePartsStyle({
    // define the part you're going to style
    control: {
        borderColor: COLORS.Line.value,
        _checked: {
            bgColor: COLORS.DigitMint.LightGreen.T500.value,
            borderColor: COLORS.DigitMint.LightGreen.T500.value,
            _hover: {
                bgColor: COLORS.DigitMint.LightGreen.T500.value,
                borderColor: COLORS.DigitMint.LightGreen.T500.value,
            },
        },
        _indeterminate: {
            bgColor: COLORS.DigitMint.LightGreen.T500.value,
            borderColor: COLORS.DigitMint.LightGreen.T500.value,
        },
    },
});

const circular = definePartsStyle({
    control: {
        rounded: 'full',
        borderColor: COLORS.Line.value,
        _checked: {
            bgColor: COLORS.DigitMint.LightGreen.T500.value,
            borderColor: COLORS.DigitMint.LightGreen.T500.value,
            _hover: {
                bgColor: COLORS.DigitMint.LightGreen.T500.value,
                borderColor: COLORS.DigitMint.LightGreen.T500.value,
            },
        },
    },
});

export const checkboxTheme = defineMultiStyleConfig({
    variants: { green: greenStyle, circular: circular },
});
