import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableAnatomy } from '@chakra-ui/anatomy';
import COLORS from 'ui/constants/colors';
import FONTS from 'ui/constants/fonts';
import { inter } from '../../../../../constants/font-family';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

export const simpleTableStyle = definePartsStyle({
    thead: {
        tr: {
            _first: {
                borderTop: '1px solid',
                borderColor: COLORS.Line.value,
                borderBottomWidth: '1px',
            },
        },
        th: {
            ...FONTS.T1.T12px.Medium500.value,
            ...inter.style,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            borderColor: COLORS.Line.value,
            padding: '.5rem .75rem',
        },
    },
    tbody: {
        td: {
            color: COLORS.Text.T400.value,
            padding: '0.75rem',
            ...FONTS.T1.T14px.Regular400.value,
            ...inter.style,
            borderColor: COLORS.Line.value,
            _last: {
                borderRight: 'none',
            },
        },
    },
});
