export type Color = string;

export type Colors = typeof COLORS;

const COLORS = {
    BG: { value: '#f8f9fc' },
    Icons: { Fill: { value: '#9D99AF' } },
    Bancontact: {
        Blue: { value: '#005697' },
        Payconiq: { value: '#ff4785' },
        Yellow: { value: '#fbd500' },
    },
    Black: {
        T100: { value: '#cccccc' },
        T200: { value: '#999999' },
        T300: { value: '#666666' },
        T400: { value: '#333333' },
        T500: { value: '#000000' },
    },
    Blue: { value: '#1d4fff' },
    Red: { value: '#F36363' },
    BrandColors: {
        Payment: { ApplePay: { value: '#f0edee' }, GPay: { value: '#f3f3f3' } },
    },
    Communication: {
        BG: { value: '#edeefc' },
        Stroke: { value: '#c2c6f4' },
        Text: { value: '#6772e5' },
    },
    DarkGreenLine: { value: '#3a5943' },
    DigitMint: {
        DarkGreen: {
            T100: { value: '#d8e8dc' },
            T200: { value: '#b1d1ba' },
            T300: { value: '#8aba98' },
            T400: { value: '#63a376' },
            T500: { value: '#3c8c54' },
            T600: { value: '#307043' },
            T700: { value: '#245432' },
            T800: { value: '#183821' },
            T900: { value: '#0c1c10' },
            A100: { value: '#3C8C55' },
        },
        LightGreen: {
            T100: { value: '#dff8e6' },
            T200: { value: '#bff2cd' },
            T300: { value: '#9febb4' },
            T400: { value: '#7fe59b' },
            T500: { value: '#5fdf83' },
            T600: { value: '#4cb268' },
            T700: { value: '#39854e' },
            T800: { value: '#265934' },
            T900: { value: '#132c1a' },
            A100: { value: '#EAFBF0' },
            A200: { value: '#D8E8DD' },
        },
    },
    Disabled: { value: '#f4f6fa' },
    Edenred: { Red: { value: '#e60a14' } },
    Error: {
        T100: { value: '#fcdfdf' },
        T200: { value: '#fac0c0' },
        T300: { value: '#f8a1a1' },
        T400: { value: '#f68282' },
        T500: { value: '#f46363' },
        T600: { value: '#c34f4f' },
        T700: { value: '#923b3b' },
        T800: { value: '#612727' },
        T900: { value: '#301313' },
    },
    Graph: {
        Blue: {
            T100: { value: '#e1f3f8' },
            T200: { value: '#c3e7f2' },
            T300: { value: '#a5dbeb' },
            T400: { value: '#87cfe5' },
            T500: { value: '#6ac3df' },
            T600: { value: '#549cb2' },
            T700: { value: '#3f7585' },
            T800: { value: '#2a4e59' },
            T900: { value: '#15272c' },
        },
        BluePurple: {
            T100: { value: '#e5e5f7' },
            T200: { value: '#ccccf0' },
            T300: { value: '#b3b2e9' },
            T400: { value: '#9a99e2' },
            T500: { value: '#807fda' },
            T600: { value: '#6766af' },
            T700: { value: '#4d4c83' },
            T800: { value: '#333357' },
            T900: { value: '#19192b' },
        },
        DarkGreen: {
            T100: { value: '#e3f4e8' },
            T200: { value: '#c8e9d2' },
            T300: { value: '#acdfbb' },
            T400: { value: '#91d4a5' },
            T500: { value: '#75c98e' },
            T600: { value: '#5ea172' },
            T700: { value: '#467955' },
            T800: { value: '#2f5039' },
            T900: { value: '#17281c' },
        },
        LightGreen: {
            T100: { value: '#e1f8e7' },
            T200: { value: '#c3f2d0' },
            T300: { value: '#a5ebb9' },
            T400: { value: '#87e5a2' },
            T500: { value: '#6adf8a' },
            T600: { value: '#54b26f' },
            T700: { value: '#3f8553' },
            T800: { value: '#2a5937' },
            T900: { value: '#152c1b' },
        },
        Orange: {
            T100: { value: '#fdebde' },
            T200: { value: '#fcd8bd' },
            T300: { value: '#fbc49d' },
            T400: { value: '#fab17c' },
            T500: { value: '#f99e5c' },
            T600: { value: '#c77e49' },
            T700: { value: '#955e37' },
            T800: { value: '#633f24' },
            T900: { value: '#311f12' },
        },
        Pink: {
            T100: { value: '#f8e1f3' },
            T200: { value: '#f2c3e7' },
            T300: { value: '#eba5dc' },
            T400: { value: '#e587d0' },
            T500: { value: '#df6ac5' },
            T600: { value: '#b2549d' },
            T700: { value: '#853f76' },
            T800: { value: '#592a4e' },
            T900: { value: '#2c1527' },
        },
        Purple: {
            T100: { value: '#f2e1f8' },
            T200: { value: '#e6c3f2' },
            T300: { value: '#d9a5eb' },
            T400: { value: '#cd87e5' },
            T500: { value: '#c06adf' },
            T600: { value: '#9a54b2' },
            T700: { value: '#733f85' },
            T800: { value: '#4d2a59' },
            T900: { value: '#26152c' },
        },
        Red: {
            T100: { value: '#f9e3e3' },
            T200: { value: '#f4c8c8' },
            T300: { value: '#efadad' },
            T400: { value: '#ea9292' },
            T500: { value: '#e57777' },
            T600: { value: '#b75f5f' },
            T700: { value: '#894747' },
            T800: { value: '#5b2f2f' },
            T900: { value: '#2d1717' },
        },
        Turquoise: {
            T100: { value: '#e1f8f2' },
            T200: { value: '#c3f2e6' },
            T300: { value: '#a5ebda' },
            T400: { value: '#87e5ce' },
            T500: { value: '#6adfc2' },
            T600: { value: '#54b29b' },
            T700: { value: '#3f8574' },
            T800: { value: '#2a594d' },
            T900: { value: '#152c26' },
        },
        Yellow: {
            T100: { value: '#fdf6de' },
            T200: { value: '#fceebd' },
            T300: { value: '#fbe69d' },
            T400: { value: '#fade7c' },
            T500: { value: '#f9d65b' },
            T600: { value: '#c7ab49' },
            T700: { value: '#958037' },
            T800: { value: '#635524' },
            T900: { value: '#312a12' },
        },
    },
    HoverDarkGrey: { value: '#dbdde9' },
    InputText: { value: '#8f95b2' },
    HelperText: { value: '#6B6688' },
    Line: { value: '#e7e9f4' },
    Maestro: {
        Blue: { value: '#0099df' },
        Purple: { value: '#6c6bbd' },
        Red: { value: '#eb001b' },
    },
    MasterCard: {
        Orange: { value: '#ff5f00' },
        Red: { value: '#eb001b' },
        Yellow: { value: '#f79e1b' },
    },
    Monizze: { Grey: { value: '#4f5d5b' }, Orange: { value: '#f59100' } },
    NightbornBlue: { value: '#065aee' },
    PlaceholderBG: { value: '#e2e4f3' },
    SharedAcrossProject: {
        Dark: { value: '#bc67e5' },
        Light: { value: '#f1edfc' },
    },
    Social: { FacebookBlue: { value: '#1877f2' } },
    Sodexo: { Blue: { value: '#293896' }, Red: { value: '#ed1c24' } },
    Stripe: { Purple: { value: '#6772e5' } },
    Stroke: { value: '#e9eaf0' },
    Success: {
        T100: { value: '#d5f6e3' },
        T200: { value: '#abedc8' },
        T300: { value: '#81e5ad' },
        T400: { value: '#57dc92' },
        T500: { value: '#2ed376' },
        T600: { value: '#24a95f' },
        T700: { value: '#1b7f47' },
        T800: { value: '#12542f' },
        T900: { value: '#092a17' },
    },
    Tag: { value: '#f2f4f9' },
    Text: {
        T100: { value: '#cdccd7' },
        T200: { value: '#9c99af' },
        T300: { value: '#6a6687' },
        T400: { value: '#39335f' },
        T500: { value: '#080038' },
        T600: { value: '#06002c' },
        T700: { value: '#040021' },
        T800: { value: '#030016' },
        T900: { value: '#01000b' },
    },
    Toast: { GreenBG: { value: '#eaf5ef' }, RedBG: { value: '#f5eaea' } },
    ToastPurple: { value: '#6772e5' },
    ToastPurpleBG: { value: '#c2c6f4' },
    Visa: {
        Blue: { value: '#3362ab' },
        DarkBlue: { value: '#15195a' },
        Yellow: { value: '#f9b50b' },
    },
    Warning: {
        T100: { value: '#ffe9d7' },
        T200: { value: '#ffd3af' },
        T300: { value: '#ffbd87' },
        T400: { value: '#ffa75f' },
        T500: { value: '#ff9137' },
        T600: { value: '#cc742c' },
        T700: { value: '#995721' },
        T800: { value: '#663a16' },
        T900: { value: '#331d0b' },
    },
    White: {
        T500: { value: '#ffffff' },
        T600: { value: '#cccccc' },
        T700: { value: '#999999' },
        T800: { value: '#666666' },
        T900: { value: '#333333' },
    },
    SurveyContext: { value: '#7B61FF' },
    Surfaces: {
        Fill: {
            T200: { value: '#FAFBFD' },
            T300: { value: '#F7F8FB' },
            T400: { value: '#3A3360' },
        },
    },
} as const;

export default COLORS;
