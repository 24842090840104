/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Digit Mint Resources API
 * Digit-mint API v2
 * OpenAPI spec version: v2
 */
import { useQuery } from '@tanstack/react-query';
import type {
    DataTag,
    DefinedInitialDataOptions,
    DefinedUseQueryResult,
    QueryFunction,
    QueryKey,
    UndefinedInitialDataOptions,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import type { GetAllUsers200, GetAllUsersParams, MeDTO } from '../../types';
import { customInstance } from '../../../config';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * The logged in user informations
 */
export const getMe = (signal?: AbortSignal) => {
    return customInstance<MeDTO>({ url: `/me`, method: 'GET', signal });
};

export const getGetMeQueryKey = () => {
    return [`/me`] as const;
};

export const getGetMeQueryOptions = <TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>>;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) => getMe(signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getMe>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>;
export type GetMeQueryError = unknown;

export function useGetMe<TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>> &
        Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetMe<TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>> &
        Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetMe<TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetMe<TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
    const queryOptions = getGetMeQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * Get all users
 * @summary Get all users
 */
export const getAllUsers = (params?: GetAllUsersParams, signal?: AbortSignal) => {
    return customInstance<GetAllUsers200>({ url: `/users`, method: 'GET', params, signal });
};

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams) => {
    return [`/users`, ...(params ? [params] : [])] as const;
};

export const getGetAllUsersQueryOptions = <TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
    params?: GetAllUsersParams,
    options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> },
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUsers>>> = ({ signal }) => getAllUsers(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getAllUsers>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUsers>>>;
export type GetAllUsersQueryError = unknown;

export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
    params: undefined | GetAllUsersParams,
    options: {
        query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> &
            Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>, 'initialData'>;
    },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
    params?: GetAllUsersParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> &
            Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>, 'initialData'>;
    },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
    params?: GetAllUsersParams,
    options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all users
 */

export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
    params?: GetAllUsersParams,
    options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
    const queryOptions = getGetAllUsersQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

    query.queryKey = queryOptions.queryKey;

    return query;
}
