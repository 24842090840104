import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListRowType } from '../../views/models/list-view/constants';

interface ActionedModelOrFolder {
    actionedItem: {
        id: number;
        type: ListRowType;
    } | null;
}

const initialState: ActionedModelOrFolder = {
    actionedItem: null,
};

const actionedItemSlice = createSlice({
    name: 'actionedItem',
    initialState,
    reducers: {
        setActionedItem: (state, action: PayloadAction<{ id: number; type: ListRowType } | null>) => {
            state.actionedItem = action.payload;
        },
        resetActionedItem: () => initialState,
    },
});

export const { setActionedItem, resetActionedItem } = actionedItemSlice.actions;

export default actionedItemSlice.reducer;
