import { StorageKey } from './storage-keys';
import { tokenStorage } from './token/token';

interface CookieOptions {
    path?: string;
    secure?: boolean;
    maxAge?: number;
    domain?: string;
    sameSite?: 'Strict' | 'Lax' | 'None';
}

function setCookie(key: string, value: string, options: CookieOptions = {}): void {
    let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    if (options.path) {
        cookieString += `; path=${options.path}`;
    }

    if (options.secure) {
        cookieString += '; secure';
    }

    if (options.maxAge) {
        cookieString += `; max-age=${options.maxAge}`;
    }

    if (options.domain) {
        cookieString += `; domain=${options.domain}`;
    }

    if (options.sameSite) {
        cookieString += `; samesite=${options.sameSite}`;
    }

    document.cookie = cookieString;
}

function getCookie(key: string): string | null {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [cookieKey, cookieValue] = cookie.split('=');
        if (decodeURIComponent(cookieKey) === key) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null;
}

function removeCookie(key: string, options: CookieOptions = {}): void {
    let cookieString = `${encodeURIComponent(key)}=; expires=${new Date(0).toUTCString()}`;

    if (options.path) {
        cookieString += `; path=${options.path}`;
    }

    if (options.secure) {
        cookieString += '; secure';
    }

    if (options.domain) {
        cookieString += `; domain=${options.domain}`;
    }

    document.cookie = cookieString;
}

function saveToStorage(key: StorageKey, value: string) {
    setCookie(key, value, {
        path: '/',
        secure: true,
        sameSite: 'Strict',
    });
}

function getFromStorage(key: StorageKey) {
    return getCookie(key);
}

function removeFromStorage(key: StorageKey) {
    removeCookie(key, {
        path: '/',
        secure: true,
    });
}

export const storage = {
    save: saveToStorage,
    get: getFromStorage,
    remove: removeFromStorage,
    token: tokenStorage,
};
