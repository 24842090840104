import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { storage } from 'utils/storage/storage';
import { TokenKey } from 'utils/storage/token/token-keys';
import axiosBetterStacktrace from 'axios-better-stacktrace';

export const AXIOS_INSTANCE: AxiosInstance = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_RESOURCES_API_URL,
});

axiosBetterStacktrace(AXIOS_INSTANCE);

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
    const source = Axios.CancelToken.source();

    const token = storage.token.get(TokenKey.ID_TOKEN);

    if (!token) {
        throw new Axios.Cancel('Bearer token not set on the request headers');
    }

    const improvedConfig = {
        ...config,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const promise = AXIOS_INSTANCE({
        ...improvedConfig,
        cancelToken: source.token,
    }).then((res) => res?.data);

    // eslint-disable-next-line
    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
    };

    return promise;
};
