import axios from 'axios';
import type Tracker from '@openreplay/tracker';

// Returns the session tracker instance.
const getSessionTracker = (): Tracker | undefined => {
    if (typeof window === 'undefined') {
        return undefined;
    }
    return (window as any).tracker;
};

// Returns if an error should be tracked.
const shouldTrackError = (error: any): boolean => {
    return error != null && !(error instanceof axios.Cancel) && !error?.errorAlreadyTracked;
};

/**
 * Creates a new tracker instance and attaches to the window.
 */
export const createSessionTracker = async () => {
    const windowObj = window as any;

    const projectKey = process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY;
    const ingestPoint = process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_INGEST_URL;

    const isOpenReplayEnabled = !!(process.env.NEXT_PUBLIC_OPENREPLAY_ENABLED === 'true' && projectKey && ingestPoint);

    const trackerExists = windowObj.tracker != null || windowObj.isInstantiatingTracker;

    if (isOpenReplayEnabled && !trackerExists) {
        // to make sure we only have one instance otherwise throws errors
        windowObj.isInstantiatingTracker = true;

        try {
            // dynamic import to only import the lib on the frontend
            const OpenreplayTracker = await import('@openreplay/tracker');

            // attaches to the window to make sure there is only one tracker instance
            windowObj.tracker = new OpenreplayTracker.default({
                projectKey,
                ingestPoint,
                // record all inputs
                defaultInputMode: 0,
                obscureTextNumbers: false,
                obscureTextEmails: false,
                obscureInputEmails: false,
                // record network calls
                network: {
                    capturePayload: true,
                } as any,
            });
        } catch (error) {
            console.error('Error creating session tracker', error);
        }
    }
};

/**
 * Tracks a session error.
 */
export const trackSessionError = (error: any, metadata?: any): void => {
    if (shouldTrackError(error)) {
        getSessionTracker()?.handleError(error, metadata);

        // Check if error is an object before adding the property
        if (typeof error === 'object' && error !== null) {
            error.errorAlreadyTracked = true;
        }

        console.error(error);
        if (metadata) {
            console.error(metadata);
        }
    }
};

/**
 * Starts tracking a session.
 */
export const startSessionTracker = (): void => {
    const tracker = getSessionTracker();
    if (!tracker?.isActive()) {
        tracker?.start();
    }
};

/**
 * Identifies the user on the session.
 */
export const identifySessionUser = (email: string): void => {
    const tracker = getSessionTracker();
    if (!tracker?.isActive()) {
        tracker?.setUserID(email);
    }
};

/**
 * Stops tracking a session.
 */
export const stopSessionTracker = (): void => {
    const tracker = getSessionTracker();
    if (tracker?.isActive()) {
        tracker?.stop();
    }
};
